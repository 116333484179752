import * as actions from 'services/timeEntries'
import deepEqual from 'fast-deep-equal'
import { getSpaceId, handleWriteErrorFactory, notify, t } from './shared'
import { logEntriesActions } from 'store/logEntries'
import { store } from 'store'
import { timeEntriesActions, timeEntriesSelectors } from 'store/timeEntries'
import { TimeEntry } from 'types/timeEntry'
import { v4 as uuid } from 'uuid'

const dispatch = store.dispatch

function getEntrySnapshot(id: string) {
  const state = store.getState()
  return timeEntriesSelectors.selectById(state, id)
}

const handleError = handleWriteErrorFactory(() => {
  actions.fetcher(getSpaceId(), 'writeError')
})

export const fetchNextPage = async () => {
  try {
    const spaceId = getSpaceId()
    await actions.fetcher(spaceId, 'nextPage')
  } catch (e) {
    handleError(e)
  }
}

export const update = async (
  id: string,
  changes: Partial<Omit<TimeEntry, 'id'>>
) => {
  const snapshot = getEntrySnapshot(id)

  try {
    dispatch(timeEntriesActions.updateEntryRequested({ id, changes }))
    await actions.updater(id, changes)
  } catch (e) {
    dispatch(timeEntriesActions.updateEntryFailed(snapshot))
    handleError(e, { id, changes })
  }
}

type DebouncedUpdateRef = Record<
  string,
  {
    timeout: ReturnType<typeof setTimeout>
    before: TimeEntry
  }
>
const debounceTimeouts = {} as DebouncedUpdateRef

export const updateDebounced = async (
  id: string,
  changes: Partial<Omit<TimeEntry, 'id'>>
) => {
  const snapshot = getEntrySnapshot(id)
  const updatedEntry = {
    ...snapshot,
    ...changes
  }

  // Update the entry only if something has changed
  if (deepEqual(snapshot, updatedEntry)) return

  // Clear the previous timeout if any
  clearTimeout(debounceTimeouts[id]?.timeout)

  // On the first call save a snapshot of the TimeEntry before any updates are made.
  if (!debounceTimeouts[id]) {
    debounceTimeouts[id] = { before: snapshot, timeout: undefined }
  }

  // Optimistic update
  dispatch(timeEntriesActions.updateEntryRequested({ id, changes }))

  // Debounced update
  debounceTimeouts[id].timeout = setTimeout(async () => {
    try {
      await actions.updater(id, changes)
    } catch (e) {
      // Revert
      dispatch(
        timeEntriesActions.updateEntryFailed(debounceTimeouts[id].before)
      )
      handleError(e, { id, changes })
    } finally {
      debounceTimeouts[id] = undefined
    }
  }, 700)
}

/**
 * Updates the app's state with the new entry and creates it in the DB
 */
export const create = async (timeEntryData: Omit<TimeEntry, 'id'>) => {
  const timeEntry = { ...timeEntryData, id: uuid() }

  dispatch(logEntriesActions.entryCreated(timeEntry))

  try {
    await actions.creator(timeEntry)
  } catch (e) {
    // Revert
    dispatch(logEntriesActions.entryDeleted(timeEntry.id))
    handleError(e, { timeEntry })
  }
}

export const remove = async (entry: TimeEntry) => {
  dispatch(logEntriesActions.entryDeleted(entry.id))

  try {
    await actions.remover(entry.id)
  } catch (error) {
    dispatch(logEntriesActions.entryCreated(entry))
    handleError(error, { entry })
    return
  }

  const undo = {
    label: t('alerts.entry.rollback'),
    callback: () => create(entry)
  }

  notify({
    message: t('alerts.entry.deleted'),
    timeout: 5000,
    actions: [undo]
  })
}

export const removeSampleEntries = async () => {
  try {
    await actions.sampleEntriesRemover()

    notify({
      message: t('alerts.entry.deletedBatch'),
      timeout: 5000
    })
  } catch (err) {
    notify({
      message: t('alerts.writeError'),
      type: 'error',
      timeout: 5000
    })
  }
}

export const restart = async (entry: Omit<TimeEntry, 'id'>) => {
  const now = new Date()
  const newEntry = { ...entry, id: uuid() }

  const running = timeEntriesSelectors
    .selectAll(store.getState())
    .filter(entry => !entry.dateEnd)

  const runningIds = running.map(entry => entry.id)

  // optimistically stop current running entries
  if (running.length) {
    const setEndDateChange = runningIds.map(id => ({
      id,
      changes: { dateEnd: now }
    }))

    dispatch(timeEntriesActions.updateEntriesRequested(setEndDateChange))
  }

  // optimistically create the new running entry
  dispatch(logEntriesActions.entryCreated(newEntry))

  try {
    try {
      // stop all running entries
      await actions.stopRunningEntries(runningIds, now)
    } catch (error) {
      // Revert the previous runningIds
      if (running.length) {
        dispatch(timeEntriesActions.updateEntriesFailed(running))
      }
    }

    // Create the new entry
    try {
      await actions.creator(newEntry)
    } catch (error) {
      // revert the created entry
      dispatch(logEntriesActions.entryDeleted(newEntry.id))
      throw error
    }
  } catch (error) {
    handleError(error, { newEntry, runningIds })
  }
}
