import React from 'react'
import deepEqual from 'fast-deep-equal'
import { useTranslation } from 'react-i18next'
import { Row, RowLeft } from '@sc/components/Row'
import { TextGroup } from '@sc/components/TextGroup'
import { useLiveTimeLabel } from 'hooks/useLiveTimeLabel'
import * as styles from './dayEntryStyles'

export type DayEntryProps = {
  showTime?: boolean
  totalSeconds: number
  date: Date
  children: React.ReactNode[]
}

export const DayEntry = React.memo(function DayEntry({
  date,
  showTime = true,
  children,
  totalSeconds
}: DayEntryProps) {
  const { t } = useTranslation()

  const title = useLiveTimeLabel(date)

  return (
    <div className={styles.stackedList()}>
      <Row
        variant="small"
        css={{
          paddingX: '$paddingContainerLevelOne',
          backgroundColor: '$surfaceFg'
        }}
      >
        <RowLeft>
          <TextGroup.Root variant="small">
            <TextGroup.Primary>{title}</TextGroup.Primary>
            {showTime && (
              <TextGroup.Secondary>
                {t('time', { seconds: totalSeconds })}
              </TextGroup.Secondary>
            )}
          </TextGroup.Root>
        </RowLeft>
      </Row>

      {children}
    </div>
  )
},
deepEqual)
