import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AddButton, ToggleButton } from './ActionButtons'
import { ContinueButton } from './ActionButtons/ContinueButton'
import { CSS } from '@stitches/react'
import { DeleteButton } from './ActionButtons/DeleteButton'
import { differenceInSeconds, getUnixTime } from 'date-fns'
import { Div } from '@sc/components/Div'
import { EnclosedEntity } from '@sc/components/EnclosedEntity'
import { Icon } from '@sc/components/Icon'
import { IconButton } from '@sc/components/Button'
import { RichEditor } from '../RichEditor'
import { RichInput } from '../RichInput'
import { RichTagList, RichTagListMode } from '../RichTagList'
import { Row, RowCenter, RowLeft, RowRight } from '@sc/components/Row'
import { searchActions } from 'store/search'
import { SlateActions } from 'modules/shared/Slate/actions'
import { SlateProvider, useSlateContext } from '../Slate/SlateContext'
import { stringToColour } from 'utils/stringToColour'
import { Tag as OdoTag } from 'types/tag'
import { TagModal } from 'components/TagModal'
import { TimeButton } from '../TimeButton'
import { TimeEntry } from 'types/timeEntry'
import { TimeRangePicker } from '@sc/components/time/TimeRangePicker'
import { Tooltip } from '@sc/components/Tooltip'
import { useBasePath } from 'hooks/useBasePath'
import { useOdoDispatch } from 'store'
import { useRouter } from 'next/router'
import { useSlateStatic } from 'slate-react'
import { useTimerUpdate } from 'hooks/useTimerUpdate'
import { useTransformColorTokenToValue } from 'hooks/useTransformColorTokenToValue'
import { useTranslationPrefix } from 'hooks/useTranslationPrefix'

type EntryCardProps = {
  entry: TimeEntry
  onEntryDatesChange?: (
    start: TimeEntry['dateStart'] | null,
    end: TimeEntry['dateEnd'] | null
  ) => void
  onAddManual?: (
    dateStart: TimeEntry['dateStart'],
    dateEnd: TimeEntry['dateEnd']
  ) => void
  onSelectTagFilter?: (id: string) => void
  toggleTimer?: () => void
  onDelete?: () => void
  onRestart?: () => void
  placeholder?: string
  css?: CSS
  showActionButton?: boolean
  autoFocus?: boolean
  avatar?: {
    displayName: string
    email: string
  }
  disabled?: boolean
  forceAddTagButton?: boolean
}

const addTag = '/[spaceId]/add-tag'
const track = '/[spaceId]'

const AddTagModal = ({ entryId, force }) => {
  const editor = useSlateStatic()
  const router = useRouter()
  const basePath = useBasePath()

  const isSelectedEntry = useMemo(
    () =>
      (router.query?.entryId === 'new-entry' && force) ||
      router.query?.entryId === entryId,
    [force, entryId, router.query.entryId]
  )

  const insertTag = (tag?: OdoTag) => {
    if (tag) {
      SlateActions.insertTag(editor, tag)
    }

    router.push(track.replace('/[spaceId]', basePath))
  }

  if (router.pathname === '/[spaceId]/add-tag' && isSelectedEntry) {
    const defaultValues = router.query.categoryId
      ? { categoryId: router.query.categoryId as string }
      : undefined

    return <TagModal defaultValues={defaultValues} onClose={insertTag} />
  }

  return null
}

type AvatarSectionProps = {
  uid: string
  name: string
}

const AvatarSection = ({ uid, name }: AvatarSectionProps) => {
  const dispatch = useOdoDispatch()
  const { t } = useTranslationPrefix('explore.entries')
  const transformTokenToValue = useTransformColorTokenToValue()

  return (
    <Tooltip
      css={{ width: 'initial' }}
      content={t('tooltips.person', { name })}
    >
      <EnclosedEntity.Root
        background={transformTokenToValue('surfaceBg')}
        onClick={() => dispatch(searchActions.personAdded(uid))}
        css={{
          marginLeft: '$paddingContainerLevelOne'
        }}
      >
        <EnclosedEntity.TextBox
          background={stringToColour(name)}
          shape="circle"
        >
          {name[0]}
        </EnclosedEntity.TextBox>
        <EnclosedEntity.Text>{name}</EnclosedEntity.Text>
      </EnclosedEntity.Root>
    </Tooltip>
  )
}

const EntryCard = ({
  entry,
  onAddManual,
  onDelete,
  onRestart,
  onSelectTagFilter,
  toggleTimer = () => {},
  onEntryDatesChange,
  avatar,
  placeholder,
  css = {},
  showActionButton = false,
  autoFocus = false,
  disabled = false,
  forceAddTagButton = false
}: EntryCardProps) => {
  const { t } = useTranslationPrefix('shared.entryCard')
  const editor = useSlateStatic()
  const router = useRouter()
  const basePath = useBasePath()
  const { type, resetState, popoverTarget } = useSlateContext()
  // Date picker
  const [startDate, setStartDate] = useState<Date | null>(entry.dateStart)
  const [endDate, setEndDate] = useState<Date | null>(entry.dateEnd)
  const [showAddManual, setShowAddManual] = useState(false)
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false)

  // Rich tag list
  const [selectedTag, setSelectedTag] = useState(undefined)
  const [tagList, setTagList] = useState<RichTagListMode>(undefined)
  const tagListTriggerRef = useRef<HTMLButtonElement>()

  const isEditTagPopoverOpen = Boolean(tagList)

  const startSeconds = getUnixTime(entry.dateStart)

  const getCurrentSeconds = useMemo(() => {
    if (startSeconds) {
      return () => Math.floor(Date.now() / 1000 - startSeconds)
    }
  }, [startSeconds])

  const getSeconds = () => differenceInSeconds(entry.dateEnd, entry.dateStart)

  /**
   * Keep external changes on sync with the internal state
   * We need to check for null because we can't compare Date objects directly
   **/
  useEffect(() => {
    if (entry.dateStart?.valueOf() !== startDate?.valueOf()) {
      setStartDate(entry.dateStart)
    }

    if (entry.dateEnd?.valueOf() !== endDate?.valueOf()) {
      setEndDate(entry.dateEnd)
    }
    // I want this to run only on entry values changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.dateStart, entry.dateEnd])

  useEffect(() => {
    function checkHorizontalScroll() {
      const editorElement = SlateActions.getEditorDOMNode(editor)
      // For some reason on safari there is always a difference of 1px between
      // editor.clientWidth & editor.scrollWidth
      // If we use `display: flex` fixes this problem but causes another one where
      // the blinking cursor not shows on empty editors
      if (editorElement.clientWidth - editorElement.scrollWidth < -10) {
        setHasHorizontalScroll(true)
      } else {
        setHasHorizontalScroll(false)
      }
    }

    checkHorizontalScroll()
    window.addEventListener('resize', checkHorizontalScroll)

    return () => window.removeEventListener('resize', checkHorizontalScroll)
  }, [entry.description, editor])

  const currentSeconds = useTimerUpdate(!entry?.dateEnd && getCurrentSeconds)

  const seconds = entry?.dateEnd ? getSeconds() : currentSeconds

  const addEntry = () => {
    if (onAddManual && startDate && endDate) {
      onAddManual(startDate, endDate)
      setShowAddManual(false)
    }
  }

  const createTag = (categoryId: string) => {
    router.push(
      addTag.replace('/[spaceId]', basePath) +
        `?entryId=${entry.id || 'new-entry'}${
          categoryId ? '&categoryId=' + categoryId : ''
        }`
    )
  }

  function manualSeconds(start: Date, end: Date) {
    // negative seconds will trigger the invalid label for the time button
    if (!start || !end) return -1
    return differenceInSeconds(end, start)
  }

  function handleScrollToEntryEnd() {
    const editorNode = SlateActions.getEditorDOMNode(editor)
    editorNode.style.paddingRight = '5rem'
    editorNode.scrollBy({
      left: 10000,
      behavior: 'smooth'
    })
  }

  /** Date picker */
  const isEditingUnstartedEntry = Boolean(!entry.dateStart && !entry.dateEnd)
  const isEditingFinishedEntry = Boolean(entry.dateStart && entry.dateEnd)

  function updateStartDate(date: Date) {
    if (isEditingUnstartedEntry) {
      setShowAddManual(true)
    }

    setStartDate(date)
  }

  const handleChangeEndDate = (date: Date) => {
    setEndDate(date)
  }

  function onDatePickerClose() {
    // no changes can be made if the EntryCard is disabled
    if (!disabled) onEntryDatesChange(startDate, endDate)
  }

  function closeTagListPopover() {
    setTagList(undefined)
    setSelectedTag(undefined)
  }

  return (
    <Div
      css={{
        background: '$surfaceFg',
        display: 'flex',
        flexDirection: 'column',
        ...css
      }}
    >
      <Div
        css={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row'
        }}
      >
        {avatar && <AvatarSection uid={entry.uid} name={avatar.displayName} />}
        <Row
          hoverable={false}
          css={{
            flex: 1,
            gridTemplateColumns: SlateActions.isEditorEmpty(editor)
              ? 'minmax(max-content, 22rem) auto max-content'
              : 'fit-content(100%) auto max-content',
            padding: '$paddingContainerLevelOne',
            '&:focus-within': {
              '& [data-scroll-button]': {
                display: 'none'
              },
              '& [data-show-on-focus]':
                popoverTarget && type === 'tag'
                  ? { display: 'none' }
                  : {
                      display: 'flex',
                      flexShrink: 0
                    }
            },
            '& > div[data-radix-popper-content-wrapper]': {
              zIndex: '$modal'
            },
            rowGap: '4px'
          }}
        >
          <RowLeft
            preventLineBreak
            css={{ paddingRight: '4px', maskImage: 'none' }}
          >
            <RichInput
              onTagSelection={tag => {
                setSelectedTag(tag)
                setTagList('replace')
              }}
              closeTagSelector={closeTagListPopover}
              onSelectTagFilter={onSelectTagFilter}
              placeholder={placeholder || t('placeholder')}
              style={{ whiteSpace: 'pre' }}
              readOnly={disabled}
              autoFocus={autoFocus}
              onEnter={showAddManual ? addEntry : toggleTimer}
            />
          </RowLeft>
          <RowCenter
            css={{ justifyContent: 'start', marginRight: '4px', zIndex: 1 }}
            onMouseDown={e => {
              // On mouse down doesn't give focus to the button
              // avoiding weird error with the editor selection
              e.preventDefault()
              e.stopPropagation()
              SlateActions.focusEditorDOMAtTheEnd(editor)
            }}
          >
            {!disabled && !isEditTagPopoverOpen && (
              <>
                <IconButton
                  as="div" // needed because Safari does not focus on buttons
                  role="button"
                  data-show-on-focus
                  id="add-tag-button"
                  ref={tagListTriggerRef}
                  variant="ghost"
                  active={Boolean(tagList)}
                  tabIndex={0} // tell the browser that this element can gain "focus"
                  css={{ display: 'none', zIndex: 2 }}
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    closeTagListPopover()
                    SlateActions.createPossiblyTagElement(editor)
                    SlateActions.focusEditorDOM(editor)
                  }}
                >
                  <Icon name="add" />
                </IconButton>
              </>
            )}

            {hasHorizontalScroll && (
              <IconButton
                variant="secondary"
                data-scroll-button
                as="div"
                role="button"
                onMouseDown={handleScrollToEntryEnd}
              >
                <Icon name="arrowRight" />
              </IconButton>
            )}
          </RowCenter>
          <RowRight
            css={
              hasHorizontalScroll
                ? {
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      // IconButton width
                      transform: 'translateX(-3.6rem)',
                      left: 0,
                      top: 0,
                      height: '100%',
                      width: '4rem',
                      boxShadow: '-20px 0px 20px 4px $colors$surfaceFg'
                    },
                    '& > *': {
                      zIndex: 1
                    }
                  }
                : {}
            }
          >
            <TimeButton
              seconds={
                showActionButton && showAddManual
                  ? manualSeconds(startDate, endDate)
                  : seconds
              }
              showSeconds={!entry?.dateEnd}
              onDatePickerClose={onDatePickerClose}
            >
              <TimeRangePicker
                startDate={startDate}
                onStartDateChange={updateStartDate}
                endDate={endDate}
                onEndDateChange={handleChangeEndDate}
                showEndDate={
                  showAddManual ||
                  isEditingFinishedEntry ||
                  isEditingUnstartedEntry
                }
                disabled={disabled}
              />
            </TimeButton>

            {showActionButton &&
              (showAddManual ? (
                <AddButton
                  disabled={manualSeconds(startDate, endDate) < 0}
                  onClick={addEntry}
                />
              ) : (
                <ToggleButton
                  showStart={!entry.dateStart}
                  disabled={seconds < 0}
                  onClick={() => {
                    toggleTimer()
                  }}
                />
              ))}

            {!disabled && onRestart && (
              <>
                <ContinueButton onContinue={onRestart} />
                <DeleteButton onClick={onDelete} />
              </>
            )}
          </RowRight>
        </Row>
      </Div>
      {!disabled && (
        <RichTagList
          mode={tagList}
          onOpenChange={value => {
            if (value === false) {
              closeTagListPopover()
            }
          }}
          onCreateTagClick={(categoryId: string) => {
            createTag(categoryId)
            closeTagListPopover()
          }}
          selectedTagIds={entry.tags}
          currentTag={selectedTag}
          triggerRef={tagListTriggerRef}
        />
      )}

      <AddTagModal entryId={entry.id} force={forceAddTagButton} />
    </Div>
  )
}

type EntryCardWrapperProps = {
  value: { description: string; nodes: string }
  onChange: (serializedValue: {
    nodes: string
    description: string
    tags: string[]
  }) => void
} & EntryCardProps

export const EntryCardWrapper = ({
  value,
  onChange,
  disabled,
  entry,
  ...props
}: EntryCardWrapperProps) => {
  return (
    <SlateProvider entryId={entry?.id}>
      <RichEditor
        value={value}
        tagIds={entry.tags}
        onChange={onChange}
        disabled={disabled}
      >
        <EntryCard entry={entry} disabled={disabled} {...props} />
      </RichEditor>
    </SlateProvider>
  )
}
