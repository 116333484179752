import React, { useMemo } from 'react'
import deepEqual from 'fast-deep-equal'
import { useMemberships } from 'hooks/useMemberships'
import { useSelector } from 'react-redux'
import { authSelectors } from 'store/auth'
import { spacesSelectors } from 'store/spaces'
import { TimeEntry as OdoTimeEntry } from 'types/timeEntry'
import { EntryCard } from '../EntryCard'
import { useOdoSelector } from 'store/store'
import { timeEntriesSelectors } from 'store/timeEntries'
import * as actions from 'services/actions/entryActions'

type TimeEntryProps = {
  id: OdoTimeEntry['id']
  onSelectTagFilter?: (id: OdoTimeEntry['id']) => void
  disabled?: boolean
  showAvatar?: boolean
}

export const TimeEntry = ({
  id,
  onSelectTagFilter,
  showAvatar,
  disabled
}: TimeEntryProps) => {
  const entrySelector = useMemo(
    () => timeEntriesSelectors.selectByIdFactory(id),
    [id]
  )

  const entry = useOdoSelector(entrySelector)

  const { users } = useMemberships()

  const spaceId = useSelector(spacesSelectors.currentSpaceId)

  const spaceUsersMap = users.reduce((map, membership) => {
    map[membership.user.id] = {
      id: membership.user.id,
      displayName: membership.user.name,
      email: membership.user.email
    }
    return map
  }, {})

  const uid = useSelector(authSelectors.uid)

  const restartEntry = async () => {
    actions.restart({
      dateStart: new Date(),
      dateEnd: null,
      nodes: entry.nodes,
      description: entry.description,
      tags: entry.tags,
      spaceId,
      uid
    })
  }

  const deleteEntry = () => {
    actions.remove(entry)
  }

  const updateEntryDates = (
    startDate: OdoTimeEntry['dateStart'],
    endDate?: OdoTimeEntry['dateEnd']
  ) => {
    const changes: Partial<OdoTimeEntry> = {
      dateStart: startDate
    }

    if (endDate) changes.dateEnd = endDate

    actions.update(entry.id, changes)
  }

  return (
    <EntryCard
      entry={entry}
      value={{
        nodes: entry.nodes,
        description: entry.description
      }}
      onChange={changes => actions.updateDebounced(entry.id, changes)}
      onEntryDatesChange={updateEntryDates}
      onSelectTagFilter={onSelectTagFilter}
      onDelete={deleteEntry}
      onRestart={restartEntry}
      avatar={showAvatar ? spaceUsersMap[entry.uid] : undefined}
      disabled={disabled}
    />
  )
}

export default React.memo(TimeEntry, deepEqual)
